<template>
  <en-dialog :model-value="modelValue" width="80%" title="工单列表" @close="$emit('update:model-value', false)">
    <template #operation>
      <button-ajax type="primary" link :method="operation.export.click">导出工单</button-ajax>
    </template>

    <tabs-maintain
      v-model="tabs.active"
      :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['SVCSTAT']) }"
      :props="{ label: 'message', value: 'code' }"
      :method="table.get"
    ></tabs-maintain>

    <table-dynamic
      :height="600"
      code="SERVICE"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="table.get"
      pagination
      :config="table.config"
    >
      <template #OPERATION="{ row }: { row: IServiceQueryDto }">
        <en-button type="primary" link :disabled="!row.fakeServiceId" @click="table.fake.click(row)">
          附表({{ row.fakeServiceId ? '已修改' : '未修改' }})
        </en-button>
        <en-button type="primary" link :disabled="row.elecdocUploaded?.value || row.status?.code !== 'PD'" @click="table.upload.click(row)">
          {{ row.elecdocUploaded?.value ? '已上传' : '上传' }}
        </en-button>
        <button-ajax
          v-if="store.user.sprayTenant?.id && row.enosprayWorkOrder?.warrantyStatus?.code === 'N'"
          link
          :method="table.qrcode.click"
          :params="row"
        >
          质保二维码
        </button-ajax>
      </template>

      <template #FREIGHT_DOCUMENT="{ row }: { row: IServiceQueryDto }"> </template>
    </table-dynamic>
  </en-dialog>
</template>

<script lang="ts">
import { h } from 'vue'
import { EnMessage, EnMessageBox } from '@enocloud/components'
import { ajax } from '@enocloud/utils'

type IServiceQueryDto = EnocloudServiceDefinitions['ServiceQueryDto'] & {
  enosprayWorkOrder?: { id?: number; warrantyStatus?: EnocloudCommonDefinitions['LookupDto'] }
}

export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        children: {
          export: {
            ajax: {
              get: {
                action: 'GET /enocloud/service/export',
                params(params) {
                  params.payload = this.table.$ajaxParams.payload
                }
              }
            },
            async click() {
              await this.operation.export.get()
              this.store.openDownload()
            }
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { statusCode: this.tabs.active }
            },
            convert(data: EnocloudServiceDefinitions['ServiceQueryDto'][]) {
              const ids = data.map((item) => item.id!)
              const polling = async () => {
                const res = await ajax('GET /enospray/workorder/service', { payload: { ids } })
                for (let item of res.data) {
                  let exist = this.table.data.find((d) => d.id === item.serviceId) as IServiceQueryDto
                  if (exist) exist.enosprayWorkOrder = { id: item.workOrderId, warrantyStatus: item.warrantyStatus }
                }
              }
              if (this.store.user.sprayTenant?.id) polling()
              return data as IServiceQueryDto[]
            }
          },
          upload: {
            action: 'POST /enocloud/elecdoc/service/:serviceId',
            loading: true
          },
          getQrcode: {
            action: 'GET /enospray/workorder/:workOrderId/qrcode'
          }
        },
        config: {
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          PLATE_NO: { header: { filter: { type: 'text', field: 'plateNo' } } },
          CUSTOMER_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customerNames',
                props: {
                  allowCreate: true,
                  ajax: {
                    action: 'GET /enocloud/common/customer',
                    params: (params, value) => (params.payload = { name: value, rsexp: 'name' })
                  },
                  props: { label: 'name', value: 'name' },
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          CUSTOMER_SERVICE_ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'customer.serviceAdvisorNames',
                props: {
                  allowCreate: true,
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, rsexp: 'name' })
                  },
                  props: { label: 'name', value: 'name' },
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          VEHICLE_SPEC: { header: { filter: { type: 'text', field: 'vehicleSpec' } } },
          CELLPHONE: { header: { filter: { type: 'text', field: 'customerCellphone' } } },
          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: { action: 'GET /enocloud/common/service/category' },
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          ACCIDENT_FLAG: {
            header: {
              filter: {
                type: 'select',
                field: 'accidentFlag',
                props: {
                  options: [
                    { message: '是', code: 'Y' },
                    { message: '否', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'quickCode',
                props: {
                  options: [
                    { message: '快速工单', code: 'Y' },
                    { message: '普通工单', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          CUSTOMER_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'customerCategoryId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/customer/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          ENTER_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['enterStartDate', 'enterEndDate'],
                props: { debug: true, type: 'daterange' }
              }
            }
          },
          COMPLETE_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['completionStartDate', 'completionEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLEMENT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settlementStartDate', 'settlementEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLE_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settleStartDate', 'settleEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          SETTLEMENT_PROGRESS: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementProgress',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['STLMTPRG'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          INVOICE_STATUS: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiced',
                props: {
                  options: [
                    { message: '已开票', code: 'Y' },
                    { message: '未开票', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },

          SENDER: {
            header: {
              filter: {
                type: 'text',
                field: 'sender'
              }
            }
          },
          SENDER_TELEPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'senderTelephone'
              }
            }
          },
          COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          },
          SETTLEMENT_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'settlementComment'
              }
            }
          },

          SETTLEMENT_BY: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementByName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'name' },
                  allowCreate: true,
                  remote: true,
                  multiple: true
                }
              }
            }
          },
          IMPORT_FROM_SERIAL_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'serviceQuotationSerialNo'
              }
            }
          },
          WORKING_TEAM_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'workingTeamName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/workingteam',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  props: { label: 'name', value: 'name' },
                  allowCreate: true,
                  multiple: true
                }
              }
            }
          },
          ASSIGNEE_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'assigneeName',
                props: {
                  ajax: { action: 'GET /enocloud/common/workingteam/member' },
                  props: { label: 'user.name', value: 'user.name' },
                  allowCreate: true
                }
              }
            }
          },
          PREPARED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['preparedStartDate', 'preparedEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          ADVISOR_NAME: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user'
                  },
                  allowCreate: true,
                  multiple: true
                }
              }
            }
          },
          SETTLEMENT_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'settlementMethod',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['SVCSTLMTD'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          RECEIPT_BYS: {
            header: {
              filter: {
                type: 'select',
                field: 'receiptBy',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user'
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          }
        },
        children: {
          fake: {
            click(row: IServiceQueryDto) {
              this.router.push('/service/business/workorder', (vm) => {
                vm.form.init()
                vm.form.data.id = row.fakeServiceId
                vm.form.get()
              })
            }
          },
          upload: {
            async click(row: IServiceQueryDto) {
              const res = (await this.ajax('GET /enocloud/elecdoc')).data[0]
              if (res.code === 'Y') {
                await this.table.upload({ paths: [row.id] })
                this.table.get()
              } else {
                EnMessage.warning('电子档案上传未开启')
              }
            }
          },
          qrcode: {
            async click(row: IServiceQueryDto) {
              const res = await this.table.getQrcode({ paths: [row.enosprayWorkOrder?.id] })
              return EnMessageBox({
                title: `扫描二维码激活终身质保-${row.plateNo}`,
                center: true,
                showConfirmButton: false,
                message: h('img', { src: res.data[0], width: 300, height: 300, class: 'm-auto' })
              })
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
